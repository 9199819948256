import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { Button } from '@mui/material';
import {
    Link,
    Route,
    Routes
} from 'react-router-dom';
import MessageComp from '../components/MessageComp.tsx';
import TypeMessageComp from '../components/TypeMessageComp.tsx';
import ChatMessageComp from '../components/ChatMessageComp.tsx';
import DetailTextComp3 from '../../../common/components/DetailTextComp3.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import SingleAppointmentComp from '../../appointments/components/SingleAppointmentComp.tsx';

function ChatScreen() {
    const [data, setData] = useState([
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },

    ]);




    const [sampleData, setSampleData] = useState([


        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Great! Super excited to check out E’s Bar. And my week is solid.  No fires to put out so grateful for that.',
            time: '10:50 PM',
            isMine: true

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },
        {
            message: 'Great! Super excited to check out E’s Bar. And my week is solid.  No fires to put out so grateful for that.',
            time: '10:50 PM',
            isMine: true

        },
        {
            message: 'Hey!  Look forward to meeting you Saturday night.  What time were you thinking?',
            time: '10:50 PM',
            isMine: false

        },



    ])

    const [activeTab, setactiveTab] = useState('0')

    const [appointments, setAppointments] = useState([


        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },






    ])

    return (
        <div style={{ width: '100%', flexDirection: 'row', height: '100vh', display: 'flex' }}>

            <div style={{ height: '100vh', display: 'flex', flex: 2, }}>

                <div style={{ backgroundColor: AppColors.white, flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column', padding: 16 }}>
                    <Button style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', }} component={Link} to={'/'} onClick={() => { }}>
                        <img src={AppImages.cliniclogoweb} style={{ height: 100, width: 100, objectFit: 'contain', }} alt="Logo" />
                        <div style={{ flexDirection: 'column', alignItems: 'center', rowGap: 5, marginTop: 16 }}>
                            <h1 style={{ fontSize: 24, color: AppColors.black, margin: 0, textAlign: 'center' }}>{'PIMS'}</h1>
                            <p style={{ fontSize: 16, color: AppColors.grey600, margin: 0, textAlign: 'center' }}>{'Your Tagline'}</p>
                        </div>
                    </Button>

                    <div style={{ width: '100%', height: 1, backgroundColor: AppColors.black20, marginTop: 16, marginBottom: 16 }}></div>

                    <div style={{ width: '100%', overflowY: 'scroll', flex: 1, scrollbarWidth: 'none', }}>
                        {data.map(item => (
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <MessageComp item={item} />
                            </div>
                        ))}
                    </div>
                </div >
            </div>

            <div style={{ height: '100vh', display: 'flex', flex: 4, backgroundColor: '#F1F1F1' }}>
                <div style={{ flexDirection: 'column', width: '100%', display: 'flex' }}>
                    <div style={{
                        flex: 1, display: 'flex', flexDirection: 'column', rowGap: 16, padding: 16, marginTop: 32, overflow: 'scroll',
                        msOverflowStyle: 'none',  // Internet Explorer 10+
                        scrollbarWidth: 'none'  // Firefox
                    }}>

                        {sampleData.map((item) => (
                            <ChatMessageComp item={item} />
                        )

                        )}

                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TypeMessageComp />
                    </div>

                </div>


            </div>
            <div style={{ height: '100vh', display: 'flex', flex: 2, backgroundColor: 'white', flexDirection: 'column', overflow: 'hidden' }}>

                <div style={{ backgroundColor: AppColors.mainGreen, flex: 1, display: 'flex', borderBottomLeftRadius: 16, borderBottomRightRadius: 16, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', }} >
                        <img src={AppImages.samplepatient} style={{ height: 150, width: 150, objectFit: 'contain', borderRadius: 1000 }} alt="Logo" />
                        <Button component={Link} to={'/patientDetail'} style={{ borderRadius: 0,padding:0,margin:0 }}>

                            <div style={{ flexDirection: 'column', alignItems: 'center', rowGap: 5, marginTop: 16 }}>
                                <h1 style={{ fontSize: 24, color: AppColors.white, margin: 0, textAlign: 'center' }}>{'Hania Amir'}</h1>
                                <p style={{ fontSize: 20, color: AppColors.white70, margin: 0, textAlign: 'center' }}>{'PID : 4587514'}</p>
                            </div>
                        </Button>
                    </div>

                </div>
                <div style={{ backgroundColor: AppColors.white, flex: 1.5, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>

                    <div style={{ flexDirection: 'row', margin: 16, paddingLeft: 16, paddingRight: 16 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 0, marginLeft: 0, backgroundColor: AppColors.topbargreen, borderRadius: 8, flex: 1 }}>

                            <Button onClick={() => { setactiveTab('0') }} style={activeTab == '0' ? innerstyles.activeTab : innerstyles.tab}>
                                <h1 style={activeTab == '0' ? innerstyles.activeText : innerstyles.text}>{'Details'}</h1>


                            </Button>
                            <Button onClick={() => { setactiveTab('1') }} style={activeTab == '1' ? innerstyles.activeTab : innerstyles.tab}>
                                <h1 style={activeTab == '1' ? innerstyles.activeText : innerstyles.text}>{'Appointments'}</h1>

                            </Button>

                        </div>
                    </div>

                    {activeTab == '0' && (
                        <div style={{ flex: 1, flexDirection: 'column', display: 'flex', rowGap: 32, marginTop: 24 }}>

                            <DetailTextComp3 img={AppImages.name} text={'Tamoor Malik'} heading={AppStrings.name} />
                            <DetailTextComp3 img={AppImages.email} text={'tamoormalik088@gmail.com'} heading={AppStrings.Email} />
                            <DetailTextComp3 img={AppImages.phone} text={'92 3215799205'} heading={AppStrings.phone} />
                            <DetailTextComp3 img={AppImages.address} text={'H#8, ST#10, Muslim Pura, Joyshah Road, Sandha Kalan, Lahore'} heading={AppStrings.address} />


                        </div>
                    )}

                    {activeTab == '1' && (
                        <div style={{ flex: 1, flexDirection: 'column', display: 'flex', rowGap: 32, marginTop: 24 }}>

                            {appointments.map((item) => (
                                <SingleAppointmentComp item={item} />

                            ))}



                        </div>
                    )}

                </div>

            </div>

        </div>

    );
}

const innerstyles = {
    tab: {
        flex: 1, display: 'flex', borderRadius: 8, padding: 16, flexDirection: 'row', alignItems: 'center', columnGap: 16, justifyContent: 'center'
    },
    activeTab: {
        flex: 1, backgroundColor: AppColors.mainGreen, display: 'flex', borderRadius: 8, padding: 16, flexDirection: 'row', alignItems: 'center', columnGap: 16, justifyContent: 'center'
    },
    text: {
        fontSize: 16, fontWeight: 'bold', color: AppColors.mainGreen, margin: 0
    },
    activeText: {
        fontSize: 16, fontWeight: 'bold', color: AppColors.white, margin: 0
    }
}

export default ChatScreen;