import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function DataTypes() {


    const [dataTypes, setDataTypes] = useState([
        {
            id: 1,
            name: 'Total Staff',
            img: AppImages.stafffocused,
            count: '4',
            navigation:'/addStaff'
        },
        {
            id: 2,
            name: 'Total Patients',
            img: AppImages.patientsfocused,
            count: '4',
            navigation:'/addPatient'

        },
        {
            id: 3,
            name: 'Total Appointments',
            img: AppImages.appointmentsfocused,
            count: '4',
            navigation:'/addAppointment'

        },
        {
            id: 4,
            name: 'Total Visits',
            img: AppImages.appointmentsfocused,
            count: '4',
            navigation:'/addAppointment'

        },
    ])
    return (
        <div style={{ display: 'flex', flexDirection: 'row', paddingRight: 16, paddingLeft: 16 }}>
            <div style={{ flexDirection: 'row', columnGap: 16, display: 'flex', flex: 1 }}>
                {dataTypes.map(item => (
                    <div style={{ rowGap: 16, flex: 1, display: 'flex', backgroundColor: 'white', padding: 16, flexDirection: 'column' }}>
                        <div style={{ borderStyle: 'solid', display: 'flex', borderRadius: 1000, borderWidth: 2, borderColor: AppColors.mainGreen, backgroundColor: AppColors.green30, width: 70, height: 70, alignItems: 'center', justifyContent: 'center' }}>
                            <img src={item.img} style={{ width: 32, height: 32 }} alt="Outline Graphic" />
                        </div>
                        <p style={{ fontSize: 20, color: AppColors.black50, fontWeight: 'bold', margin: 0 }}>{item.name}</p>
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                            <h1 style={{ fontSize: 32, fontWeight: 'bold', color: AppColors.black, flex: 1, margin: 0,}}>{item.count}</h1>
                            <Button sx={{ p: 0, m: 0 }} style={{  textTransform: 'none',  margin: 0, display: 'flex', flexDirection: 'row' }} component={Link} to={item.navigation}>
                                <div style={{ display: 'flex', borderRadius: 1000, borderStyle: 'solid', borderWidth: 2, borderColor: AppColors.mainGreen, paddingRight: 48, paddingLeft: 48, paddingTop: 4, paddingBottom: 4, alignItems: 'center', justifyContent: 'center' }}>
                                    <p style={{ fontSize: 18, fontWeight: 'bold', color: AppColors.mainGreen, margin: 0 }}>{'Add'}</p>
                                </div>
                            </Button>

                        </div>
                    </div>
                ))}
            </div>


        </div>
    );
}

export default DataTypes;