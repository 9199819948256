import { AppColors } from "../../../../utils/AppColors.tsx"



const ChatMessageComp = ({ item }) => {



    return (

        <div style={{}}>
            <div style={{ flexDirection: item.isMine ? 'row-reverse' : 'row', alignItems: 'flex-end', columnGap: 16, display: 'flex' }}>
                <div style={{ rowGap: 5, flex: 1, alignItems: 'flex-end', backgroundColor: item.isMine ? AppColors.blue : AppColors.white, borderTopRightRadius: 8, borderTopLeftRadius: 8, borderBottomLeftRadius: item.isMine ? 8 : 0, borderBottomRightRadius: item.isMine ? 0 : 8, padding: 16 }}>
                    <p style={{ margin: 0, color: item.isMine ? AppColors.white : AppColors.black, flex: 1 }}>{item.message}</p>


                </div>

                <div style={{ rowGap: 5, }}>


                    <p style={{ margin: 0, color: AppColors.textgrey, textAlign: 'right' }}>{item.time}</p>


                </div>





            </div>



        </div>

    )
}

export default ChatMessageComp