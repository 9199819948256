import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';

function AppointmentComp2({ item }) {



    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 16, marginLeft: 16, backgroundColor: AppColors.white, borderRadius: 8, padding: 16, flex: 1, justifyContent: 'top' }}>

            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', columnGap: 16 }}>
                <h1 style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.black, margin: 0 }}>{item.appointmentdate}</h1>
                <div style={{ borderRadius: 1000, backgroundColor: AppColors.mainGreen, width: 32, height: 32, alignItems: 'center', justifyContent: 'center',display:'flex' }}>
                    <p style={{ fontSize: 16, fontWeight: '700', color: AppColors.white, margin: 0 }}>{item.appointments.length}</p>
                </div>




            </div>

            <div style={{ flexDirection: 'row', rowGap: 16, display: 'flex', flex: 1, marginTop: 16, columnGap: 24,flexWrap:'wrap' }}>
                {item.appointments.map(item => (
                    <div style={{ backgroundColor: AppColors.white, borderRadius: 8, padding: 16, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)', flex: 1 }}>
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                            <div style={{ rowGap: 5, flex: 1 }}>
                                <p style={{ fontSize: 20, fontWeight: 'bold', color: AppColors.pureblack, margin: 0 }}>{item.patientName}</p>
                                <p style={{ fontSize: 16, fontWeight: '600', color: AppColors.black, opacity: 0.7, margin: 0 }}>{item.doctorName}</p>
                                <p style={{ fontSize: 16, color: AppColors.red50, margin: 0 }}>{'Last Appointment : ' + item.lastappointment}</p>
                            </div>
                            <div style={{ rowGap: 5, alignItems: 'flex-end' }}>
                                <img src={AppImages.sampledoctor} style={{ width: 64, height: 64, borderRadius: 1000 }} />

                            </div>
                        </div>

                        <div style={{ flexDirection: 'row', marginTop: 16, display: 'flex', alignItems: 'center' }}>

                            <div style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                <div style={{ flexDirection: 'row', columnGap: 8, flex: 1, alignItems: 'center', display: 'flex' }}>
                                    <div style={{ borderRadius: 16, backgroundColor: AppColors.mainGreen, alignItems: 'center', justifyContent: 'center', paddingRight: 15, paddingLeft: 15, }}>
                                        <p style={{ color: AppColors.white, marginLeft: 8, marginRight: 8, marginTop: 4, marginBottom: 4 }}>{AppStrings.chat}</p>
                                    </div>
                                    <div style={{ borderRadius: 16, borderWidth: 1, borderStyle: 'solid', borderColor: AppColors.mainGreen, alignItems: 'center', justifyContent: 'center', paddingRight: 15, paddingLeft: 15 }}>
                                        <p  style={{whiteSpace:'nowrap', color: AppColors.mainGreen, marginLeft: 8, marginRight: 8, marginTop: 4, marginBottom: 4 }}>{AppStrings.seereports}</p>
                                    </div>
                                </div>
                                <div style={{ marginLeft: 8, alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.black, margin: 0 }}>{item.appointmentdate}</p>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.black, margin: 0 }}>{item.appointmentTime}</p>
                                </div>
                            </div>

                        </div>

                    </div>
                ))}
            </div>

        </div>
    );
}

export default AppointmentComp2;