import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import { Button } from '@mui/material';

function AddChecker({ activeTab, setactiveTab,toggleActiveStatus }) {



    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 100, marginLeft: 100, borderRadius: 8 }}>
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: 16, flex: 1, justifyContent: 'center' }}>

                {activeTab.map((item, index) => (
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', display: 'flex', rowGap: 8, width: 200 }}>
                                <div style={{ width: 64, height: 64, display: 'flex', borderRadius: 1000, backgroundColor: item.filled ? AppColors.mainGreen : AppColors.black50, alignItems: 'center', justifyContent: 'center' }}>
                                    <h1 style={{ margin: 0, color: AppColors.white, fontSize: 16 }}>{index + 1}</h1>
                                </div>
                            <h1 style={{ margin: 0, color: item.filled ? AppColors.mainGreen : AppColors.black50, fontSize: 16 }}>{item.name}</h1>

                        </div>

                        {index != (activeTab.length - 1) && (
                            <div style={{ flex: 1, height: 5, borderRadius: 100, backgroundColor: item.filled ? AppColors.mainGreen : AppColors.black50, width: 200 }}></div>

                        )}
                    </div>
                ))}
            </div>

        </div>
    );
}


const innerstyles = {
    tab: {
        flex: 1, display: 'flex', borderRadius: 8, padding: 16, flexDirection: 'row', alignItems: 'center', columnGap: 16, justifyContent: 'center'
    },
    activeTab: {
        flex: 1, backgroundColor: AppColors.mainGreen, display: 'flex', borderRadius: 8, padding: 16, flexDirection: 'row', alignItems: 'center', columnGap: 16, justifyContent: 'center'
    },
    text: {
        fontSize: 16, fontWeight: 'bold', color: AppColors.mainGreen, margin: 0
    },
    activeText: {
        fontSize: 16, fontWeight: 'bold', color: AppColors.white, margin: 0
    }
}
export default AddChecker;