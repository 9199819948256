import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';


import AppointmentComp2 from '../components/AppointmentComp2.tsx';
import AppointmentHandler from '../components/AppointmentHandler.tsx';
import AddChecker from '../components/AddChecker.tsx';
import { Button } from '@mui/material';

function AddAppointment() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }


    const [name, setname] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };
    const handleName = (event) => {
        setname(event.target.value);
    };
    const handleNumber = (event) => {
        setNumber(event.target.value);
    };
    const handleAddress = (event) => {
        setAddress(event.target.value);
    };

    const [checkerData, setCheckerData] = useState([
        {
            id: 1,
            name: 'Personal Information',
            filled: true,
            active: true
        },
        {
            id: 2,
            name: 'Credentials',
            filled: false,
            active: false

        },
        {
            id: 3,
            name: 'Roles',
            filled: false,
            active: false

        },
    ])

    const setActiveStatus = (id) => {
        setCheckerData(prevData =>
            prevData.map(item =>
                item.id === id
                    ? { ...item, active: true }
                    : { ...item, active: false }
            )
        );
    };

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Add Appointment'} userData={item} />
            {/* <AddChecker toggleActiveStatus={setActiveStatus} activeTab={checkerData} setactiveTab={setCheckerData} /> */}

            <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'column' }}>
                {checkerData[0].active && (
                    <div style={{ flex: 1, display: 'flex', padding: 16, rowGap: 16, flexDirection: 'column', }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{'Appointment Information'}</p>

                        <input
                            type="text"
                            id="myInput"
                            placeholder='Patient'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={name}
                            onChange={handleName}
                        />
                        <input
                            type="number"
                            id="myInput"
                            placeholder='Department'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={number}
                            onChange={handleNumber}
                        />
                        <input
                            type="text"
                            id="myInput"
                            placeholder='Doctor'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={address}
                            onChange={handleAddress}
                        />

                        <input
                            type="text"
                            id="myInput"
                            placeholder='Date'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={email}
                            onChange={handleEmail}
                        />

                        <input
                            type="text"
                            id="myInput"
                            placeholder='Start Time'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={email}
                            onChange={handleEmail}
                        />


                    </div>
                )}



                <div style={{ backgroundColor: AppColors.grey500, height: 1 }}>

                </div>

                <div style={{ padding: 16, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'row', columnGap: 16 }}>
                 
                    <Button style={{ borderRadius: 4, borderStyle: 'solid', borderWidth: 1, borderColor: AppColors.mainGreen }}>
                        <p style={{ color: AppColors.mainGreen, margin: 0, fontSize: 16, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32 }}>{'Add & Print'}</p>
                    </Button>

                    <Button style={{ borderRadius: 4, backgroundColor: AppColors.mainGreen }}>
                        <p style={{ color: AppColors.white, margin: 0, fontSize: 16, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32 }}>{'Add'}</p>
                    </Button>
                </div>
            </div>


        </div>
    );
}

export default AddAppointment;