import React, { useState } from 'react';
import '../../../App.css'
import { AppColors } from '../../../utils/AppColors.tsx';
import { AppImages } from '../../../utils/AppImages.tsx';
import { AppStrings } from '../../../utils/AppStrings.tsx';
import { styles } from '../../../utils/AppFonts.tsx';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'
import { setisLoggedin } from '../../../redux/AppReducer.tsx';
import { useDispatch } from 'react-redux';

function SignInScreen() {




    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };

    const dispatch = useDispatch()

    return (
        <div className='App-header2'>
            <div style={{ backgroundColor: AppColors.lightGreen, flex: 1, position: 'relative', overflow: 'hidden', height: '100vh' }}>
                <div style={{ height: '60%', position: 'relative', }}>
                    <div style={{ width: 250, height: 250, backgroundColor: AppColors.greencircle, borderRadius: '50%', position: 'absolute', right: -50, top: 50 }} />
                    <div style={{ width: 220, height: 220, backgroundColor: AppColors.greencircle, borderRadius: '50%', position: 'absolute', right: -100, marginTop: 250 }} />
                    <img src={AppImages.outlinegraphic} style={{ width: 250, height: 250, position: 'absolute', left: -150, top: 50, }} alt="Outline Graphic" />

                </div>
                <div style={{ rowGap: 0, flexDirection: 'column', display: 'flex', height: '40%', position: 'relative', justifyContent: 'flex-end', paddingRight: 24, paddingLeft: 24, paddingBottom: 0, marginRight: 150 }}>
                    <h1 style={{ fontSize: 64, color: AppColors.black, marginRight: 50, marginBottom: 14, textAlign: 'left' }}>{AppStrings.LetsGetStarted}</h1>
                    <Button component={Link} to="/signup" variant="contained" style={{ backgroundColor: AppColors.black, borderRadius: 100, padding: 16, color: AppColors.lightGreen, fontSize: 24, fontWeight: 'bold', marginBottom: 50 }}>
                        {AppStrings.JoinNow}
                    </Button>


                </div>

            </div>
            <div style={{ backgroundColor: AppColors.white, flex: 1, display: 'flex', paddingLeft: 200, paddingRight: 200, flexDirection: 'column', }}>
                <div style={{ rowGap: 5, flexDirection: 'column', display: 'flex', alignItems: 'flex-start' }}>
                    <h1 style={{ fontSize: 64, color: AppColors.mainGreen, marginRight: 50, marginBottom: 0, textAlign: 'left' }}>{AppStrings.AlreadyHaveAnAccountLogin}</h1>
                    <p style={{ fontSize: 24, color: AppColors.pureblack, marginRight: 50, marginBottom: 1, marginTop: 0, fontWeight: '600' }}>{AppStrings.WelcomeToMyClinic}</p>
                    <p style={{ fontSize: 16, color: AppColors.grey600, marginRight: 50, marginBottom: 0, marginTop: 0 }}>{AppStrings.LetsLogin}</p>
                </div>
                <div style={{ rowGap: 16, flexDirection: 'column', display: 'flex', marginTop: 24 }}>
                    <input
                        type="email"
                        id="myInput"
                        placeholder='Email'
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={email}
                        onChange={handleEmail}
                    />
                    <input
                        type="password"
                        id="myInput"
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={password}
                        placeholder='Password'
                        onChange={handlePassword}
                    />
                </div>

                <Button onClick={() => { dispatch(setisLoggedin(true)) }} style={{ backgroundColor: AppColors.black, borderRadius: 1000, width: '75px', height: '75px', marginTop: 24, alignItems: 'center', justifyContent: 'center', display: 'flex', alignSelf: 'flex-end' }}>
                    <img src={AppImages.whitearrow} style={{ width: '30px', height: '30px' }} alt="Outline Graphic" />
                </Button>




            </div>
        </div>

    );
}

export default SignInScreen;