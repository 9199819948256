export const AppStrings = {
    CompleteClinicManagement :'Complete Clinic Management',
    NowYouCanManageYourAllClinicFunctionalitiesFromThePalmOfYourHandFromAddingPatientsToManagingYourStaffAllTheFeaturesFromOneGoToPlace:'Now you can manage your all Clinic functionalities from the palm of your hand. From adding patients to managing your staff all the features from one go to place.',
    LetsGetStarted:'Let’s Get Started',
    JoinNow:'Join Now',
    AlreadyHaveAnAccountLogin:'Already have an account? ',
    login:'Login',
    signup:'Sign Up',
    DontHaveAnAccountSignUp:'Don’t have an account? ',
    ClinicName:'Clinic Name',
    DoctorName:'Doctor Name',
    Department:'Department',
    Departments:'Departments',
    yes:'Yes',
    no:'No',
    choose:'Choose',
    ok:'Ok',
    changePassword:'Change Password',
    tryagain:'Try Again',
    Email:'Email',
    Password:'Password',
    WelcomeToMyClinic:'Welcome to My Clinic',
    CreateAnAccount:'Create an account',
    LetsLogin:'Let’s Login',
    totalstaff:'Total Staff',
    addstaff:'Add Staff',
    totalappointments:'Total Appointments',
    addappointment:'Add Appointment',
    totalchats:'Total Chats',
    chats:'Chats',
    totalpatients:'Total Patients',
    addpatients:'Add Patient',
    appointments:'Appointments',
    seeall:'see all',
    chat:'Chat',
    seereports:'See Reports',
    cancel:'Cancel',
    reschedule:'Reschedule',

    appointment:'Appointment',
    appointmentinformation:'Appointment Information',
    patient:'Patient',
    doctor:'Doctor',
    date:'Date',
    startTime:'Start Time',
    add:'Add',
    addnprint:'Add & Print',


    selectPatient:'Select Patient',
    selectDoctor:'Select Doctor',
    selectDepartment:'Select Department',

    clinicInformation:'Clinic Information',
    bloodGroup:'Blood Group',
    age:'Age',
    male:'Male',
    female:'Female',
    neutral:'Neutral',
    personalInformation:'Personal Information',

    patientName:'Patient Name',
    address:'Address',
    phone:'Phone',
    officePhone:'Office Phone',
    country:'Country',
    state:'State',
    city:'City',
    zipCpde:'Zip Code',
    userName:'User Name',
    appointmentPermission:'Appointment Permission',
    patientPermission:'Patient Permission',
    staffType:'Staff Type',
    name:'Name',
    credentials:'Credentials',
    bloodPressure:'Blood Pressure',
    bloodSugar:'Blood Sugar',
    pulse:'Pulse',
    prescription:'Prescription',
    lorem:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem...',
    medicines:'Medicines',
    symptoms:'Symptoms',
    print:'Print',
    mobile:'Mobile',
    gender:'Gender',
    edit:'Edit',
    patientInformation:'Patient Information',
    permissions:'Permissions',
    savenprint:'Save & Print',
    save:'Save',
    congratulationsyourpatienthasbeencreatedsuccessfullyandaddedtothelist:'Congratulations ! your patient has been created successfully and added to the list.',
    patientcreatedsuccessfully:'Patient Created Successfully',
    serverError:'Server Error',
    SorrySomethingwentwrongTryagainlatertoaddanewPatient:'Sorry! Something went wrong. Try again later to add a new Patient',
    seereason:'See Reason',
    done:'Done',

    profile:'Profile', 
    logout:'Logout',
    reportType:'Report Type',
    addReport:'Add Report',
    reportName:'Report Name',
    attachReport:'Attach Report',
    Areyousuretocancel:'Are you sure to cancel?',
    AreyousureyouwanttocanceltheappointmentThePatientandAdminwillreceivethenotificationofthiscancelation:'Are you sure you want to cancel the appointment? The Patient and Admin will receive the notification of this cancelation.',

    basic:'Basic',
    standard:'Standard',
    premium:'Premium',
    upcomingAppointments:'Upcoming Appointments',
    requestAppointment:'Request Appointment',
    seeappointments:'See Appointment',
    yourappointmenthasbeenrequestedsuccessfullyyouwillreceiveanotificationonconfirmation:'Your appointment has been requested successfully, you will receive a notification on confirmation',
    success:'Success',




}