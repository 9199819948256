// App.js
import HomeScreen from '../ui/admin/home/screens/HomeScreen.tsx';
import SignInScreen from '../ui/common/screens/SignInScreen.tsx';
import SignUpScreen from '../ui/common/screens/SignUpScreen.tsx';
import {
  Link,
  Route,
  Routes
} from 'react-router-dom';

import { AppImages } from '../utils/AppImages.tsx';
import Dashboard from '../ui/admin/home/screens/Dashboard.tsx';



function AdminNav() {

  const data = [
    { id: 1, name: 'Home', img: AppImages.home, focused: true },
    { id: 2, name: 'Chat', img: AppImages.chat, focused: false },
    { id: 3, name: 'Patients', img: AppImages.patients, focused: false },
    { id: 4, name: 'Appointments', img: AppImages.appointments, focused: false },
    { id: 4, name: 'Profile', img: AppImages.settings, focused: false },

    { id: 5, name: 'Departments', img: AppImages.departments, focused: false },
    { id: 6, name: 'Symptoms', img: AppImages.symptoms, focused: false },
    { id: 7, name: 'Staff', img: AppImages.staff, focused: false },
    { id: 8, name: 'Membership', img: AppImages.membership, focused: false },
    { id: 9, name: 'Staff Roles', img: AppImages.staffroles, focused: false },
    { id: 10, name: 'Report Types', img: AppImages.reporttypes, focused: false },
    { id: 11, name: 'Clinic Info', img: AppImages.clinicinfo, focused: false },

  ];
  return (
    <div style={{ backgroundColor: 'yellow', height: '100vh', display: 'flex', flexDirection: 'row' }}>

      <div style={{ backgroundColor: 'yellow', flex: 5, display: 'flex' }}>
        <Dashboard />
      </div>
    </div>
  );
}

export default AdminNav;
