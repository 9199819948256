import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';
import StaffComp from '../components/StaffComp.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


function Staff() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }


    const [sampleData, setSampleData] = useState([

        {
            staffName: 'Joesph Kezik',
            staffNumber: '+92-3215799205',
           
            departments:['Family Practice'],
        },
        {
            staffName: 'Joesph Kezik',
            staffNumber: '+92-3215799205',
           
            departments:['Family Practice','Astronomy'],
        },
        {
            staffName: 'Joesph Kezik',
            staffNumber: '+92-3215799205',
           
            departments:['Astronomy'],
        },
        
        {
            staffName: 'Joesph Kezik',
            staffNumber: '+92-3215799205',
           
            departments:['Family Practice'],
        },
        {
            staffName: 'Joesph Kezik',
            staffNumber: '+92-3215799205',
           
            departments:['Family Practice','Astronomy'],
        },
        
        
    ])



    const [activeTab, setactiveTab] = useState('0')
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Staff'} userData={item} />
            <div style={{    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', flexDirection: 'row', rowGap: 16, display: 'grid',  marginTop: 16, columnGap: 16, flexWrap: 'wrap',marginRight: 16, marginLeft: 16, }}>

                {sampleData.map(item => (
                    <StaffComp item={item} />
                ))}
            </div>

            <Button component={Link} to={'/addStaff'} style={{ width: 100, height: 100, backgroundColor: AppColors.mainGreen, borderRadius: 1000, position: 'absolute', bottom: 64, right: 64, alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: 32, height: 32 }} src={AppImages.plus} />
            </Button>
        </div>
    );
}

export default Staff;