import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';

function SingleAppointmentComp({ item }) {



    return (



        <div style={{ flexDirection: 'row', rowGap: 16, display: 'flex', flexWrap: 'wrap', }}>

            <div style={{ flexDirection: 'row', display: 'flex',flex:1,columnGap:12,marginLeft:16,marginRight:16,}}>
                <div style={{ rowGap: 5, alignItems: 'flex-end',flexDirection:'column',display:'flex' }}>
                    <img src={AppImages.sampledoctor} style={{ width: 64, height: 64, borderRadius: 1000 }} />
                </div>
                <div style={{ rowGap: 5, flex: 1,alignItems:'center',display:'flex' }}>
                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.black,  margin: 0 }}>{item.doctorName}</p>
                </div>
                <div style={{ marginLeft: 8, alignItems: 'flex-end', display: 'flex', flexDirection: 'column',justifyContent:'center',rowGap:8}}>
                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.black, margin: 0 }}>{item.appointmentdate}</p>
                    <p style={{ fontSize: 14, fontWeight: '400', color: AppColors.black, margin: 0 }}>{item.appointmentTime}</p>
                </div>

            </div>



        </div>

    );
}

export default SingleAppointmentComp;