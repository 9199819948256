import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { Button } from '@mui/material';
import {
    Link,
    Route,
    Routes,
    useLocation
} from 'react-router-dom';
import SignInScreen from '../../../common/screens/SignInScreen.tsx';
import SignUpScreen from '../../../common/screens/SignUpScreen.tsx';
import Navbar from '../../../common/components/Navbar.tsx';
import ChatScreen from '../../chat/screens/ChatScreen.tsx';
import HomeScreen from './HomeScreen.tsx';
import Profile from '../../../common/screens/Profile.tsx';
import Departments from '../../screens/Departments.tsx';
import Symptoms from '../../screens/Symptoms.tsx';
import Membership from '../../screens/Membership.tsx';
import StaffRoles from '../../screens/StaffRoles.tsx';
import ReportTypes from '../../screens/ReportTypes.tsx';
import ClinicInfo from '../../screens/ClinicInfo.tsx';
import Appointments from '../../appointments/screens/Appointments.tsx';
import Patients from '../../patients/screens/Patients.tsx';
import Staff from '../../staff/screens/Staff.tsx';
import AddAppointment from '../../appointments/screens/AddAppointment.tsx';
import AddStaff from '../../staff/screens/AddStaff.tsx';
import AddPatient from '../../patients/screens/AddPatient.tsx';
import AppointmentDetail from '../../appointments/screens/AppointmentDetail.tsx';
import PatientDetail from '../../patients/screens/PatientDetail.tsx';
import StaffDetail from '../../staff/screens/StaffDetail.tsx';
function Dashboard() {
    const location = useLocation();
    const hideNavbar = location.pathname === '/chat';

    return (
        <div style={{ width: '100%', flexDirection: 'row', height: '100vh', display: 'flex', backgroundColor: AppColors.greybg }}>
           {!hideNavbar && (
                <div style={{ display: 'flex', flex: 1, height: '100vh' }}>
                    <Navbar />
                </div>
            )}
            <div style={{ height: '100vh', display: 'flex', flex: 5 }}>

                <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/chat" element={<ChatScreen />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/departments" element={<Departments />} />
                    <Route path="/symptoms" element={<Symptoms />} />
                    <Route path="/staff" element={<Staff />} />
                    <Route path="/membership" element={<Membership />} />
                    <Route path="/staffroles" element={<StaffRoles />} />
                    <Route path="/reporttypes" element={<ReportTypes />} />
                    <Route path="/clinicinfo" element={<ClinicInfo />} />
                    <Route path="/appointments" element={<Appointments />} />
                    <Route path="/patients" element={<Patients />} />
                    <Route path="/addAppointment" element={<AddAppointment />} />
                    <Route path="/addStaff" element={<AddStaff />} />
                    <Route path="/addPatient" element={<AddPatient />} />
                    <Route path="/appointmentDetail" element={<AppointmentDetail />} />
                    <Route path="/patientDetail" element={<PatientDetail />} />
                    <Route path="/staffDetail" element={<StaffDetail />} />


                </Routes>
            </div>

        </div>

    );
}

export default Dashboard;