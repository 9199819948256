import { configureStore } from "@reduxjs/toolkit";
import AppReducer from "./AppReducer.tsx";

const store = configureStore({
    reducer: {
        AppReducer: AppReducer,
    }
});

export default store;
