import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';


import AppointmentComp2 from '../components/AppointmentComp2.tsx';
import AppointmentHandler from '../components/AppointmentHandler.tsx';
import AddChecker from '../components/AddChecker.tsx';
import { Button } from '@mui/material';
import DetailComp from '../components/DetailComp.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';

function AppointmentDetail() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }


    const items = {
        personaldetails: {
            patientName: 'Hania Amir',
            appointmentDate: '24th Feb 2024 - 12:00 AM',
            patientimg: AppImages.samplepatient
        },
        medicalDetails: {
            symptoms: [
                'Flue',
                'Azma',
                'Headache',

            ],
            medicines: [
                'Panadol',
                'Desprin',
                'Med 3',

            ],
        }
    }

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>
            <TopBar page={'Appointment Details'} userData={item} />
            {/* <AddChecker toggleActiveStatus={setActiveStatus} activeTab={checkerData} setactiveTab={setCheckerData} /> */}

            <div style={{ rowGap: 16, overflow: 'scroll', flexDirection: 'column', display: 'flex', scrollbarWidth: 'none' }}>
                <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: AppColors.mainGreen, borderRadius: 8, backgroundColor: AppColors.green30, display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'row', padding: 16, columnGap: 16 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', columnGap: 16 }} >
                            <img src={AppImages.samplepatient} style={{ height: 80, width: 80, objectFit: 'contain', borderRadius: 1000 }} alt="Logo" />
                            <h1 style={{ fontSize: 24, color: AppColors.mainGreen, margin: 0, textAlign: 'center' }}>{'Hania Amir'}</h1>
                        </div>

                    </div>
                    <div style={{ display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'row', padding: 16, flex: 1 }}>
                        <div style={{ flexDirection: 'column', alignItems: 'flex-end', rowGap: 5, flex: 1, display: 'flex' }}>
                            <h1 style={{ fontSize: 24, fontWeight: '600', color: AppColors.mainGreen, margin: 0, textAlign: 'center' }}>{'Appointment at'}</h1>
                            <p style={{ fontSize: 24, fontWeight: '600', color: AppColors.mainGreen, margin: 0, textAlign: 'center' }}>{'24th Feb 2024'}</p>
                        </div>

                    </div>

                </div>
                <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'column', padding: 16, rowGap: 12 }}>
                    <DetailComp type={'single'} text={'100/24 mmHg'} heading={AppStrings.bloodPressure} />
                    <DetailComp type={'single'} text={'100 mM'} heading={AppStrings.bloodSugar} />
                    <DetailComp type={'single'} text={'100 / Minute'} heading={AppStrings.pulse} />
                    <DetailComp type={'multi'} text={items.medicalDetails.symptoms} heading={AppStrings.symptoms} />
                    <DetailComp type={'multi'} text={items.medicalDetails.medicines} heading={AppStrings.medicines} />
                    <DetailComp copy={true} type={'single'} text={AppStrings.lorem} heading={AppStrings.prescription} />
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 16, paddingBottom: 16, columnGap: 16 }}>
                <Button style={{ flex: 1, }}>
                    <div style={{ padding: 16, display: 'flex', flexDirection: 'row', flex: 1, backgroundColor: AppColors.mainGreen, borderRadius: 8, alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{ fontSize: 16, color: AppColors.white, margin: 0, fontWeight: 'bold', textAlign: 'center' }}>{'Email'}</h1>
                    </div>
                </Button>
                <Button style={{ flex: 1, }}>
                    <div style={{ padding: 16, borderWidth: 1, borderStyle: 'solid', borderColor: AppColors.mainGreen, display: 'flex', flexDirection: 'row', flex: 1, borderRadius: 8, alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{ fontSize: 16, color: AppColors.mainGreen, margin: 0, fontWeight: 'bold', textAlign: 'center' }}>{'Print'}</h1>
                    </div>
                </Button>

            </div>
        </div>
    );
}

export default AppointmentDetail;