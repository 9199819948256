import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function AppointmentComp() {


    const [dataTypes, setDataTypes] = useState([

        {
            patientName: 'Hania Amir',
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM'
        },
        {
            patientName: 'Hania Amir',
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM'
        }

    ])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 16, marginLeft: 16, backgroundColor: AppColors.white, borderRadius: 8, padding: 16, flex: 1, justifyContent: 'top' }}>

            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', }}>
                <div style={{ flex: 1 }}>
                    <h1 style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.black, flex: 1, margin: 0 }}>{'Appointments'}</h1>
                    <p style={{ fontSize: 16, fontWeight: '600', color: AppColors.black50, flex: 1, margin: 0 }}>{'These are your appointments for the day.'}</p>
                </div>
                <div style={{ borderRadius: 1000, borderStyle: 'solid', borderWidth: 2, borderColor: AppColors.mainGreen, paddingRight: 32, paddingLeft: 32, paddingTop: 4, paddingBottom: 4, alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ fontSize: 16, fontWeight: '700', color: AppColors.mainGreen, margin: 0 }}>{'See All'}</p>
                </div>



            </div>

            <div style={{ flexDirection: 'column', rowGap: 16, display: 'flex', flex: 1, marginTop: 16 }}>
                {dataTypes.map(item => (
                    <Button  onClick={()=>{}} component={Link} to={'/appointmentDetail'} style={{cursor: 'pointer',flexDirection: 'row', display: 'flex', }}>
                        <div style={{ flex: 1, backgroundColor: AppColors.white, borderRadius: 8, padding: 16, boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)' }}>
                            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>

                                <div style={{ rowGap: 5, flex: 1, flexDirection: 'column', alignItems: 'flex-start', display: 'flex' }}>
                                    <p style={{ fontSize: 20, fontWeight: 'bold', color: AppColors.pureblack, margin: 0 }}>{item.patientName}</p>
                                    <p style={{ fontSize: 16, fontWeight: '600', color: AppColors.black, opacity: 0.7, margin: 0 }}>{item.doctorName}</p>
                                    <p style={{ fontSize: 16, color: AppColors.red50, margin: 0 }}>{'Last Appointment : ' + item.lastappointment}</p>
                                </div>
                                <div style={{ rowGap: 5, alignItems: 'flex-end' }}>
                                    <img src={AppImages.sampledoctor} style={{ width: 64, height: 64, borderRadius: 1000 }} />

                                </div>
                            </div>

                            <div style={{ flexDirection: 'row', marginTop: 16, display: 'flex', alignItems: 'center' }}>

                                <div style={{ flexDirection: 'row', flex: 1, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ flexDirection: 'row', columnGap: 8, flex: 1, alignItems: 'center', display: 'flex' }}>
                                        <Button onClick={()=>{}} component={Link} to={'/chat'}  onMouseDown={e => e.stopPropagation()} style={{ flexDirection: 'row', display: 'flex', padding: 0, borderRadius: 16, }}>

                                            <div style={{ borderRadius: 16, backgroundColor: AppColors.mainGreen, alignItems: 'center', justifyContent: 'center', paddingRight: 15, paddingLeft: 15, }}>
                                                <p style={{ color: AppColors.white, marginLeft: 8, marginRight: 8, marginTop: 4, marginBottom: 4 }}>{AppStrings.chat}</p>
                                            </div>
                                        </Button>
                                        <Button onClick={()=>{}} component={Link} to={'/chat'} onMouseDown={e => e.stopPropagation()} style={{ flexDirection: 'row', display: 'flex', padding: 0, borderRadius: 16, }}>

                                            <div style={{ borderRadius: 16, borderWidth: 1, borderStyle: 'solid', borderColor: AppColors.mainGreen, alignItems: 'center', justifyContent: 'center', paddingRight: 15, paddingLeft: 15 }}>
                                                <p style={{ color: AppColors.mainGreen, marginLeft: 8, marginRight: 8, marginTop: 4, marginBottom: 4 }}>{AppStrings.seereports}</p>
                                            </div>
                                        </Button>
                                    </div>
                                    <div style={{ marginLeft: 8, alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                                        <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.black, margin: 0 }}>{item.appointmentdate}</p>
                                        <p style={{ fontSize: 16, fontWeight: 'normal', color: AppColors.black, margin: 0 }}>{item.appointmentTime}</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </Button>
                ))}
            </div>

        </div>
    );
}

export default AppointmentComp;