import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import DetailComp from '../../appointments/components/DetailComp.tsx';
import AppointmentComp from '../../home/components/AppointmentComp.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SingleAppointmentComp from '../../appointments/components/SingleAppointmentComp.tsx';

function PatientDetail() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }




    const [appointments, setAppointments] = useState([


        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },
        {
            doctorName: 'Joseph Kezik',
            lastappointment: '11th Feb 2024',
            appointmentdate: 'Today',
            appointmentTime: '4:00 PM',
            img: AppImages.sampledoctor
        },






    ])

    const Separator = () => (
        <div style={{ height: '1px', backgroundColor: '#ccc', margin: 12 }} />
    );

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Patient Details'} userData={item} />
            {/* <AddChecker toggleActiveStatus={setActiveStatus} activeTab={checkerData} setactiveTab={setCheckerData} /> */}


            <div style={{ borderWidth: 1, borderStyle: 'solid', borderColor: AppColors.mainGreen, borderRadius: 8, backgroundColor: AppColors.green30, display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'row', padding: 16, columnGap: 16 }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', columnGap: 16 }} >
                        <img src={AppImages.samplepatient} style={{ height: 100, width: 100, objectFit: 'contain', borderRadius: 1000 }} alt="Logo" />
                        <h1 style={{ fontSize: 32, color: AppColors.mainGreen, margin: 0, textAlign: 'center' }}>{'Hania Amir'}</h1>
                    </div>

                </div>
                <div style={{ display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'row', padding: 16, flex: 1 }}>
                    <div style={{ flexDirection: 'column', alignItems: 'flex-end', rowGap: 5, flex: 1, display: 'flex', justifyContent: 'center' }}>
                        {/* <h1 style={{ fontSize: 24, fontWeight: '600', color: AppColors.mainGreen, margin: 0, textAlign: 'center' }}>{'Appointment at'}</h1> */}
                        <p style={{ fontSize: 24, fontWeight: '600', color: AppColors.mainGreen, margin: 0, textAlign: 'center' }}>{'PID : 1254564'}</p>


                    </div>

                </div>

            </div>

            <div style={{ flexDirection: 'row', display: 'flex', columnGap: 16, marginLeft: 16, marginRight: 16, }}>
                <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', marginTop: 0, flexDirection: 'column', padding: 16, rowGap: 12 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 32, fontWeight: 'bold', flex: 1 }}>{'Personal Information'}</p>
                        <Button component={Link} to={'/addPatient'} style={{ borderRadius: 1000 }}>
                            <div style={{ borderRadius: 1000, borderStyle: 'solid', borderWidth: 2, borderColor: AppColors.mainGreen, paddingRight: 32, paddingLeft: 32, paddingTop: 4, paddingBottom: 4, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 16, fontWeight: '700', color: AppColors.mainGreen, margin: 0 }}>{'Edit'}</p>
                            </div>
                        </Button>

                    </div>

                    <DetailComp type={'single'} text={'Hania Amir'} heading={AppStrings.name} />
                    <DetailComp type={'single'} text={'hania@gmail.com'} heading={AppStrings.Email} />
                    <DetailComp type={'single'} text={'+92565456465456'} heading={AppStrings.phone} />
                    <DetailComp type={'single'} text={'Female'} heading={AppStrings.gender} />
                    <DetailComp type={'single'} text={'O-'} heading={AppStrings.bloodGroup} />
                    <DetailComp type={'single'} text={'Lahore, Pakistan'} heading={AppStrings.address} />
                </div>
                <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', marginTop: 0, flexDirection: 'column', padding: 16, rowGap: 12 }}>
                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 32, fontWeight: 'bold', flex: 1 }}>{'Appointments'}</p>
                        <Button component={Link} to={'/appointments'} style={{ borderRadius: 1000 }}>
                            <div style={{ borderRadius: 1000, borderStyle: 'solid', borderWidth: 2, borderColor: AppColors.mainGreen, paddingRight: 32, paddingLeft: 32, paddingTop: 4, paddingBottom: 4, alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ fontSize: 16, fontWeight: '700', color: AppColors.mainGreen, margin: 0 }}>{'See All'}</p>
                            </div>
                        </Button>

                    </div>
                    <div style={{ flex: 1, flexDirection: 'column', display: 'flex', rowGap: 0, marginTop: 24 }}>

                        {appointments.map((item, index) => (
                            <React.Fragment key={item.id || index}>
                                <SingleAppointmentComp item={item} />
                                {index < appointments.length - 1 && <Separator />}
                            </React.Fragment>
                        ))}



                    </div>
                </div>
            </div>



        </div>
    );
}

export default PatientDetail;