import React, { useState } from 'react';
import { AppColors } from '../../../utils/AppColors.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';



function TopBar({ page, userData }) {


    return (
        <div style={{ display: 'flex', flexDirection: 'row', padding: 16, alignItems: 'center' }}>
            <h1 style={{ fontSize: 24, fontWeight: 'bold', flex: 1, margin: 0 }}>{page}</h1>
            <div style={{ flexDirection: 'row', display: 'flex', columnGap: 16, alignItems: 'center' }}>

                <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                    <h1 style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.black50, margin: 0 }}>{'Hello,' + '\u00A0'}</h1>
                    <h1 style={{ fontSize: 24, fontWeight: 'bold', margin: 0, }}>{userData.name}</h1>
                </div>
                <Button component={Link} to={'/profile'} style={{ width: 56, height: 56, borderRadius: 1000 }}>
                <img src={userData.img} style={{ width: 56, height: 56, borderRadius: 1000 }} alt="Outline Graphic" />
                </Button>
            </div>
        </div>
    );
}

export default TopBar;