import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function StaffComp({ item }) {



    return (
        <Button component={Link} to={'/staffDetail'} style={{ display: 'flex', backgroundColor: AppColors.white, borderRadius: 8, padding: 16, alignItems: 'center', justifyContent: 'center', flex: 1, rowGap: 8, flexDirection: 'column' }}>
            <img src={AppImages.samplepatient} style={{ width: 64, height: 64, borderRadius: 1000 }} />


            <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{item.patientName}</p>
            <p style={{ color: AppColors.black50, margin: 0, fontSize: 16, fontWeight: 'normal' }}>{item.patientId}</p>

            <div style={{ alignItems: 'flex-start', rowGap: 8 }}>
                {item.departments.map((items) => <div style={{ borderRadius: 8, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', columnGap: 8, display: 'flex' }}>
                    <img src={AppImages.department} style={{ width: 16, height: 16 }} />
                    <p style={{ color: AppColors.mainGreen, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{items}</p>
                </div>)}

            </div>


        </Button>
    );
}

export default StaffComp;