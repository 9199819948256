// App.js
import SignInScreen from '../ui/common/screens/SignInScreen.tsx';
import SignUpScreen from '../ui/common/screens/SignUpScreen.tsx';
import {
  Link,
  Route,
  Routes
} from 'react-router-dom';



function AuthNav() {
  return (
    <div style={{backgroundColor:'yellow',height:'100vh'}}>

      <Routes>
        <Route path="/"
          element={<SignInScreen />} />
        <Route path="/signup"
          element={<SignUpScreen />} />
      </Routes>
      </div>
  );
}

export default AuthNav;
