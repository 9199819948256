import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';
import DataTypes from '../components/DataTypes.tsx';
import AppointmentComp from '../components/AppointmentComp.tsx';
import ChatComp from '../../chat/components/ChatComp.tsx';

function HomeScreen() {

   
   const item={
    name:'Hania Amir', 
    img:AppImages.samplepatient
   }
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex',flexDirection:'column',rowGap:16,overflow:'scroll' }}>
            <TopBar page={'Dashboard'} userData={item}/>
            <DataTypes/>
            <div style={{flexDirection:'row',display:'flex',width:'100%'}}>
            <AppointmentComp/>
            <ChatComp/>

            </div>
        </div>
    );
}

export default HomeScreen;