import { Button } from "@mui/material"
import { AppColors } from "../../../utils/AppColors.tsx"
import { AppImages } from "../../../utils/AppImages.tsx"


const DetailTextComp3 = ({ heading, text, img, }) => {



    return (

        <div style={{ columnGap: 8, flexDirection: 'row', alignItems: 'center', display: 'flex', marginLeft: 16,marginRight:16 }}>
            <div style={{ width: 48, height: 48, borderRadius: 1000, backgroundColor: AppColors.black10, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <img style={{ width: 24, height: 24, }} src={img} />
            </div>
            <div style={{ rowGap: 4, flex: 1, marginRight: 8, flexDirection: 'column', justifyContent: 'center' }}>
                <p style={{ margin: 0, color: AppColors.black50, fontSize: 14, fontWeight: 'bold' }}>{heading}</p>
                {text && (
                    <p style={{ margin: 0, color: AppColors.black, fontSize: 18, fontWeight: 'bold' }}>{text}</p>
                )}

            </div>






        </div>
    )
}



export default DetailTextComp3