// App.js
import './App.css';
import AdminNav from './navigation/AdminNav.tsx';
import AuthNav from './navigation/AuthNav.tsx';
import { useSelector } from 'react-redux';

function App() {

  const selector = useSelector(state => state.AppReducer);


  return (
   <div style={{backgroundColor:'grey',height:'100vh'}}>

    {selector.isLoggedin?
      <AdminNav />:
      <AuthNav/>
    }
      </div>
  );
}

export default App;
