import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';

import ChatComp from '../../chat/components/ChatComp.tsx';
import DataTypes from '../../home/components/DataTypes.tsx';
import AppointmentComp from '../../home/components/AppointmentComp.tsx';
import AppointmentComp2 from '../components/AppointmentComp2.tsx';
import AppointmentHandler from '../components/AppointmentHandler.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function Appointments() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }


    const [sampleData, setSampleData] = useState([

        {
            appointmentdate: 'Today',
            status: 'schedule',
            appointments: [
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '4:00 PM'
                },
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '4:00 PM'
                },
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '4:00 PM'
                },
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '4:00 PM'
                },
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '4:00 PM'
                }
            ]
        },
        {
            appointmentdate: '7th Apr 2024',
            status: 'schedule',

            appointments: [
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '14:00 PM'
                },
                {
                    patientName: 'Hania Amir',
                    doctorName: 'Joseph Kezik',
                    lastappointment: '11th Feb 2024',
                    appointmentdate: 'Today',
                    appointmentTime: '14:00 PM'
                },

            ]
        },



    ])


    const [activeTab, setactiveTab] = useState('0')
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Appointments'} userData={item} />
            <AppointmentHandler activeTab={activeTab} setactiveTab={setactiveTab} />

            {sampleData.map(item => (
                <AppointmentComp2 item={item} />))}

            <Button component={Link} to={'/addAppointment'} style={{ width: 100, height: 100, backgroundColor: AppColors.mainGreen, borderRadius: 1000, position: 'absolute', bottom: 64, right: 64, alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: 32, height: 32 }} src={AppImages.plus} />
            </Button>
        </div>
    );
}

export default Appointments;