import { Button } from "@mui/material"
import { AppColors } from "../../../../utils/AppColors.tsx"


const MessageComp = ({ item }) => {



    return (

        <Button style={{ alignItems: 'center', justifyContent: 'flex-start',flex:1 }}>
            <div style={{ flexDirection: 'row', alignItems: 'center', columnGap: 16, display: 'flex', flex: 1 }}>
                <div style={{ rowGap: 5, alignItems: 'flex-end' }}>
                    <img src={item.userimg} style={{ width: 50, height: 50, borderRadius: 1000 }} />
                </div>

                <div style={{ rowGap: 5, flex: 1, justifyContent: 'flex-start' }}>
                    <div style={{ flexDirection: 'row', alignItems: 'center', columnGap: 8, display: 'flex', justifyContent: 'flex-start' }}>
                        <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.pureblack, margin: 0 }}>{item.username}</p>
                        {item.messages && (
                            <div style={{ width: 16, height: 16, backgroundColor: AppColors.blue, borderRadius: 100, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <p style={{ fontSize: 12, color: AppColors.white, margin: 0 }}>{item.messages}</p>

                            </div>
                        )}

                        <p style={{ color: AppColors.textgrey, flex: 1, textAlign: 'right', margin: 0 }}>{item.time}</p>

                    </div>
                    <p style={{ color: AppColors.black, margin: 0, textAlign: 'start' }}>{item.lastmessage}</p>
                </div>





            </div>



        </Button>

    )
}

export default MessageComp