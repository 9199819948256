import React, { useState } from 'react';
import '../../../App.css'
import { AppColors } from '../../../utils/AppColors.tsx';
import { AppImages } from '../../../utils/AppImages.tsx';
import { AppStrings } from '../../../utils/AppStrings.tsx';
import { styles } from '../../../utils/AppFonts.tsx';
import Button from '@mui/material/Button';

function SignUpScreen() {
    


    const [clinicName, setClinicName] = useState('');
    const [doctorName, setDoctorName] = useState('');
    const [department, setDepartment] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleClinicName = (event) => {
        setClinicName(event.target.value);
    };
    const handleDoctorName = (event) => {
        setDoctorName(event.target.value);
    };
    const handleDepartment = (event) => {
        setDepartment(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };
    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
  
    return (
        <div style={{backgroundColor:'yellow',height:'100vh'}}>

            <div style={{ backgroundColor: AppColors.white, flex: 1, display: 'flex', flexDirection: 'column', height: '100vh', alignItems: 'center' }}>
                <a href="https://www.google.com">
                <img src={AppImages.logo} style={{ height: 150, width: 250, objectFit: 'contain', objectPosition: 'top', marginBottom: 0,position:'absolute',left:0,}} alt="Logo" />
                </a>
                <div style={{ rowGap: 5, flexDirection: 'column', display: 'flex', marginRight: 200, marginLeft: 200, alignItems: 'center', marginTop: 100}}>
                    <h1 style={{ fontSize: 64, color: AppColors.black, marginRight: 50, marginBottom: 0, marginTop: 0 }}>{AppStrings.WelcomeToMyClinic}</h1>
                    <p style={{ fontSize: 24, color: AppColors.grey600, marginRight: 50, marginBottom: 1, marginTop: 0, fontWeight: '500' }}>{AppStrings.CreateAnAccount}</p>
                </div>
                <div style={{ rowGap: 16, flexDirection: 'column', display: 'flex', marginTop: 24, marginRight: 200, marginLeft: 200, width: '100vh' }}>

                    <input
                        type="text"
                        id="myInput"
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={clinicName}
                        placeholder='Clinic Name'
                        onChange={handleClinicName}
                    />
                    <input
                        type="text"
                        id="myInput"
                        placeholder='Department'
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={department}
                        onChange={handleDepartment}
                    />
                    <input
                        type="text"
                        id="myInput"
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={doctorName}
                        placeholder='Doctor Name'
                        onChange={handleDoctorName}
                    />
                    <input
                        type="email"
                        id="myInput"
                        placeholder='Email'
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={email}
                        onChange={handleEmail}
                    />
                    <input
                        type="password"
                        id="myInput"
                        style={{
                            borderRadius: 100,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.greystroke}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={password}
                        placeholder='Password'
                        onChange={handlePassword}
                    />
                </div>

                <div style={{ rowGap: 8, width: '100vh', flexDirection: 'column', display: 'flex', position: 'relative', justifyContent: 'flex-end', paddingRight: 24, paddingLeft: 24, paddingBottom: 0,flex:1,marginBottom:24 }}>
                    <Button variant="contained" style={{ backgroundColor: AppColors.black, borderRadius: 100, padding: 16, color: AppColors.white, fontSize: 20, fontWeight: 'bold', marginBottom: 0 }}>
                        {AppStrings.signup}
                    </Button>
                    <div style={{flexDirection:'row',display:'flex',alignSelf:'center',columnGap:8}}>
                    <p style={{ fontSize: 20, color: AppColors.grey500,  marginBottom: 1, marginTop: 0, fontWeight: '500' }}>{AppStrings.AlreadyHaveAnAccountLogin}</p>
                    <a href="https://www.google.com">

                    <p style={{ fontSize: 20, color: AppColors.black,  marginBottom: 1, marginTop: 0, fontWeight: 'bold' }}>{AppStrings.login}</p>
</a>
                    </div>


                </div>




            </div>
        </div>

    );
}

export default SignUpScreen;