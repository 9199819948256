import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';



import { Button } from '@mui/material';
import AddChecker from '../../appointments/components/AddChecker.tsx';

function AddStaff() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }


    const [name, setname] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };
    const handleName = (event) => {
        setname(event.target.value);
    };
    const handleNumber = (event) => {
        setNumber(event.target.value);
    };
    const handleAddress = (event) => {
        setAddress(event.target.value);
    };

    const [checkerData, setCheckerData] = useState([
        {
            id: 1,
            name: 'Personal Information',
            filled: true,
            active: true
        },
        {
            id: 2,
            name: 'Credentials',
            filled: false,
            active: false

        },
        {
            id: 3,
            name: 'Roles',
            filled: false,
            active: false

        },
    ])


    const [activeIndex, setActiveIndex] = useState(0);

    const handleNext = () => {
        if(activeIndex==2){
            return
        }
        setCheckerData(prevData => 
            prevData.map((item, index) => 
                index === activeIndex 
                ? { ...item, filled: true, active: false } 
                : index === activeIndex + 1 
                ? { ...item, active: true,filled:true } 
                : { ...item, active: false }
            )
        );
        setActiveIndex(prevIndex => Math.min(prevIndex + 1, checkerData.length - 1));
    };

    const handleBack = () => {
        setCheckerData(prevData => 
            prevData.map((item, index) => 
                index === activeIndex 
                ? { ...item, filled: false, active: false } 
                : index === activeIndex - 1 
                ? { ...item, active: true } 
                : { ...item, active: false }
            )
        );
        setActiveIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };
    const setActiveStatus = (id) => {
        setCheckerData(prevData =>
            prevData.map(item =>
                item.id === id
                    ? { ...item, active: true }
                    : { ...item, active: false }
            )
        );
    };

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Add Staff'} userData={item} />
            <AddChecker toggleActiveStatus={setActiveStatus} activeTab={checkerData} setactiveTab={setCheckerData} />

            <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', marginLeft: 16, marginRight: 16, marginTop: 0, flexDirection: 'column' }}>
                {checkerData[0].active && (
                    <div style={{ flex: 1, display: 'flex', padding: 16, rowGap: 16, flexDirection: 'column', }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{'Fill out personal details'}</p>

                        <input
                            type="text"
                            id="myInput"
                            placeholder='Name'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={name}
                            onChange={handleName}
                        />
                        <input
                            type="number"
                            id="myInput"
                            placeholder='Phone'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={number}
                            onChange={handleNumber}
                        />
                        <input
                            type="email"
                            id="myInput"
                            placeholder='Email'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={address}
                            onChange={handleAddress}
                        />
                        <div style={{ flexDirection: 'row', columnGap: 16, display: 'flex' }}>
                            <input
                                type="text"
                                id="myInput"
                                placeholder='Country'
                                style={{
                                    fontSize: 14,
                                    borderRadius: 8,
                                    flex: 1,
                                    backgroundColor: AppColors.screenbg,
                                    padding: 16,
                                    border: `1px solid ${AppColors.black50}`, // Specify the border color
                                    outline: 'none', // Optionally remove the outline
                                }}
                                value={email}
                                onChange={handleEmail}
                            />
                            <input
                                type="text"
                                id="myInput"
                                placeholder='State'
                                style={{
                                    fontSize: 14,
                                    borderRadius: 8,
                                    flex: 1,
                                    backgroundColor: AppColors.screenbg,
                                    padding: 16,
                                    border: `1px solid ${AppColors.black50}`, // Specify the border color
                                    outline: 'none', // Optionally remove the outline
                                }}
                                value={email}
                                onChange={handleEmail}
                            />

                        </div>

                        <div style={{ flexDirection: 'row', columnGap: 16, display: 'flex' }}>
                            <input
                                type="text"
                                id="myInput"
                                placeholder='City'
                                style={{
                                    fontSize: 14,
                                    borderRadius: 8,
                                    flex: 1,
                                    backgroundColor: AppColors.screenbg,
                                    padding: 16,
                                    border: `1px solid ${AppColors.black50}`, // Specify the border color
                                    outline: 'none', // Optionally remove the outline
                                }}
                                value={email}
                                onChange={handleEmail}
                            />
                            <input
                                type="text"
                                id="myInput"
                                placeholder='Zip Code'
                                style={{
                                    fontSize: 14,
                                    borderRadius: 8,
                                    flex: 1,
                                    backgroundColor: AppColors.screenbg,
                                    padding: 16,
                                    border: `1px solid ${AppColors.black50}`, // Specify the border color
                                    outline: 'none', // Optionally remove the outline
                                }}
                                value={email}
                                onChange={handleEmail}
                            />

                        </div>


                    </div>
                )}

                {checkerData[1].active && (
                    <div style={{ flex: 1, display: 'flex', padding: 16, rowGap: 16, flexDirection: 'column', }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{'Create Credentials for Staff'}</p>

                        <input
                            type="text"
                            id="myInput"
                            placeholder='Username'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={name}
                            onChange={handleName}
                        />
                        <input
                            type="password"
                            id="myInput"
                            placeholder='Password'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={number}
                            onChange={handleNumber}
                        />



                    </div>
                )}
                {checkerData[2].active && (
                    <div style={{ flex: 1, display: 'flex', padding: 16, rowGap: 16, flexDirection: 'column', }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{'Select Roles for this Doctor'}</p>

                        <input
                            type="text"
                            id="myInput"
                            placeholder='Staff Type'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={name}
                            onChange={handleName}
                        />
                        <input
                            type="password"
                            id="myInput"
                            placeholder='Appointment Permissions'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={number}
                            onChange={handleNumber}
                        />

                        <input
                            type="password"
                            id="myInput"
                            placeholder='Patient Permissions'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={number}
                            onChange={handleNumber}
                        />

                        <input
                            type="password"
                            id="myInput"
                            placeholder='Departments'
                            style={{
                                fontSize: 14,
                                borderRadius: 8,
                                backgroundColor: AppColors.screenbg,
                                padding: 16,
                                border: `1px solid ${AppColors.black50}`, // Specify the border color
                                outline: 'none', // Optionally remove the outline
                            }}
                            value={number}
                            onChange={handleNumber}
                        />

                    </div>
                )}

                <div style={{ backgroundColor: AppColors.grey500, height: 1 }}>

                </div>

                <div style={{ padding: 16, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'row', columnGap: 16 }}>
                    {checkerData[0].active == false && (
                        <Button onClick={handleBack} style={{ borderRadius: 4, borderStyle: 'solid', borderWidth: 1, borderColor: AppColors.mainGreen }}>
                            <p style={{ color: AppColors.mainGreen, margin: 0, fontSize: 16, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32 }}>{'Back'}</p>
                        </Button>
                    )}

                    <Button onClick={handleNext} style={{ borderRadius: 4, backgroundColor: AppColors.mainGreen }}>
                        <p style={{ color: AppColors.white, margin: 0, fontSize: 16, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32 }}>{checkerData[2].active ? 'Save' : 'Next'}</p>
                    </Button>
                </div>
            </div>


        </div>
    );
}

export default AddStaff;