import { Button } from "@mui/material"
import { AppColors } from "../../../../utils/AppColors.tsx"
import { AppImages } from "../../../../utils/AppImages.tsx"



const DetailComp = ({ heading, text, type, copy }) => {



    return (

        <div style={{ rowGap: 4, display: 'flex', flexDirection: 'column' }}>
            <div style={{ flexDirection: 'row', alignItems: 'center', columnGap: 0, display: 'flex' }}>
                <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 20, fontWeight: 'bold' }}>{heading}</p>

                {copy && (
                    <Button style={{padding:0,margin:0}}>
                        <img resizeMode='contain' style={{ width: 24, height: 24, transform: [{ rotate: '180deg' }] }} src={AppImages.copy} />
                    </Button>
                )}


            </div>

            {
                type == 'multi' ?
                    <div style={{ flexDirection: 'row', width: '100%', columnGap: 16, flexWrap: 'wrap', rowGap: 8,display:'flex',marginTop:8 }}>
                        {text?.map((item, index) => (
                            <p key={index} style={{ color: AppColors.mainGreen, borderWidth: 1,borderStyle:'solid', borderRadius: 100, borderColor: AppColors.mainGreen, margin: 0,paddingLeft:16,paddingRight:16,paddingTop:4,paddingBottom:4 }}>{item}</p>
                        ))}
                    </div>
                    :
                    <p style={{ color: AppColors.black50, margin: 0, fontSize: 24, fontWeight: '400' }}>{text}</p>
            }



        </div>
    )
}



export default DetailComp