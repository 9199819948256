import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'

import { Button } from '@mui/material';
import {
    Link,
    Route,
    Router,
    Routes
} from 'react-router-dom';
import { AppColors } from '../../../utils/AppColors.tsx';
import TopBar from '../components/TopBar.tsx';
import { AppImages } from '../../../utils/AppImages.tsx';


function Profile() {
    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }
    const [name, setname] = useState('');
    const [number, setNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };
    const handlePassword = (event) => {
        setPassword(event.target.value);
    };
    const handleName = (event) => {
        setname(event.target.value);
    };
    const handleNumber = (event) => {
        setNumber(event.target.value);
    };
    const handleAddress = (event) => {
        setAddress(event.target.value);
    };
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Profile'} userData={item} />
            <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', margin: 16, marginTop: 0, flexDirection: 'column' }}>
                <div style={{ flex: 1, display: 'flex', padding: 16, rowGap: 16, flexDirection: 'column', }}>
                    <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{'Personal Information'}</p>

                    <input
                        type="text"
                        id="myInput"
                        placeholder='Name'
                        style={{
                            fontSize: 14,
                            borderRadius: 8,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.black50}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={name}
                        onChange={handleName}
                    />
                    <input
                        type="number"
                        id="myInput"
                        placeholder='Number'
                        style={{
                            fontSize: 14,
                            borderRadius: 8,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.black50}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={number}
                        onChange={handleNumber}
                    />
                    <input
                        type="text"
                        id="myInput"
                        placeholder='Address'
                        style={{
                            fontSize: 14,
                            borderRadius: 8,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.black50}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={address}
                        onChange={handleAddress}
                    />
                    <input
                        type="email"
                        id="myInput"
                        placeholder='Email'
                        style={{
                            fontSize: 14,
                            borderRadius: 8,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.black50}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={email}
                        onChange={handleEmail}
                    />
                    <input
                        type="password"
                        id="myInput"
                        style={{
                            fontSize: 14,
                            borderRadius: 8,
                            backgroundColor: AppColors.screenbg,
                            padding: 16,
                            border: `1px solid ${AppColors.black50}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }}
                        value={password}
                        placeholder='Password'
                        onChange={handlePassword}
                    />
                </div>
                <div style={{ backgroundColor: AppColors.grey500, height: 1 }}>

                </div>

                <div style={{ padding: 16, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    <Button style={{ borderRadius: 4, backgroundColor: AppColors.mainGreen }}>
                        <p style={{ color: AppColors.white, margin: 0, fontSize: 16, fontWeight: 'bold', paddingLeft: 32, paddingRight: 32 }}>{'Save'}</p>
                    </Button>
                </div>
            </div>

        </div>
    );
}

export default Profile;