import { Button } from "@mui/material"
import { AppColors } from "../../../../utils/AppColors.tsx"
import { AppImages } from "../../../../utils/AppImages.tsx"




const TypeMessageComp = ({ sendMessage, setMessage, message }) => {

    return (

        <div style={{ margin: 16, backgroundColor: AppColors.white, padding: 8,paddingLeft:16,paddingRight:16, borderRadius: 8, flexDirection: 'row', columnGap: 16, display: 'flex' }}>

            <input 
               style={{ 
                flex: 1, 
                padding: 0, 
                backgroundColor: 'transparent', 
                borderWidth: 0, 
                fontSize: 16, 
                outline: 'none' 
            }}
             placeholder='Type Here' value={message} />

            <div style={{ flexDirection: 'row', alignItems: 'center', columnGap: 8 }}>
                <Button style={{borderRadius:1000}} >
                    <div style={{ width: 48, height: 48, borderRadius: 100, backgroundColor: AppColors.blue, alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
                        <img style={{ width: 20, height: 20 }} src={AppImages.attach} />
                    </div>
                </Button>
                <Button style={{borderRadius:1000}}    >
                    <div style={{ width: 48, height: 48, borderRadius: 100, backgroundColor: AppColors.blue, alignItems: 'center', justifyContent: 'center', display: 'flex' }} >
                        <img style={{ width: 20, height: 20 }} src={AppImages.send} />
                    </div>
                </Button>


            </div>


        </div>

    )
}



export default TypeMessageComp