import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppImages } from '../../../utils/AppImages.tsx';
import StaffComp from '../staff/components/StaffComp.tsx';
import TopBar from '../../common/components/TopBar.tsx';
import AdminItem from '../components/AdminItem.tsx';
import { AppColors } from '../../../utils/AppColors.tsx';
import { Button } from '@mui/material';
import { AppStrings } from '../../../utils/AppStrings.tsx';



function Membership() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }

    const [texts, setText] = useState('')

    const [items, setItem] = useState({
        selectall: false,
        departments: [
            {
                title: 'Dengue',
                selected: false,
            },
            {
                title: 'Alzaima',
                selected: false,
            },

        ]

    })
    const [selected, setSelected] = useState(false)
    const [add, setAdd] = useState(false)

    const changeItem = (index) => {
        const updatedDepartments = [...items.departments];
        updatedDepartments[index].selected = !updatedDepartments[index].selected;
        setItem({
            ...items,
            departments: updatedDepartments
        });

        const anySelected = updatedDepartments.some(item => item.selected);
        setSelected(anySelected)
    };

    const selectAllItems = () => {
        const updatedDepartments = items.departments.map(item => ({
            ...item,
            selected: true
        }));

        setItem({
            ...items,
            departments: updatedDepartments
        });
    };

    const deleteSelectedItems = () => {
        const updatedDepartments = items.departments.filter(item => !item.selected);

        setItem({
            ...items,
            departments: updatedDepartments
        });

        setSelected(false)
    };

    const addItem = () => {

        const newItem = {
            title: texts,
            selected: false
        }
        const updatedDepartments = [...items.departments, newItem];
        setItem({
            ...items,
            departments: updatedDepartments
        });

        setText('')

        setAdd(false)
    };
    const handleText = (event) => {
        setText(event.target.value);
    };

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>

            <div style={{ flex: 1, backgroundColor: AppColors.white, display: 'flex', margin: 16, marginTop: 32, flexDirection: 'column' }}>
                <div style={{ flex: 1, display: 'flex', padding: 16, rowGap: 16, flexDirection: 'column', }}>
                    <h1 style={{ color: AppColors.pureblack, margin: 0, fontSize: 24, fontWeight: 'bold' }}>{'CHOOSE YOUR PLAN'}</h1>

                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'normal' }}> Start with&nbsp;</p>
                        <p style={{ color: AppColors.mainGreen, margin: 0, fontSize: 16, fontWeight: 'normal' }}> 14 days free trial.&nbsp;</p>
                        <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'normal' }}> Upgrade or downgrade anytime&nbsp;</p>

                    </div>

                    <div style={{ flexDirection: 'row', display: 'flex', margin: 32, flex: 1, columnGap: 32 }}>
                        <div style={{ flex: 1, flexDirection: 'column', borderRadius: 16, backgroundColor: AppColors.white, margin: 16, display: 'flex', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', }}>
                            <div

                                style={{ flexDirection: 'column', display: 'flex', background: 'linear-gradient(to right, #B0CCC850, #B0CCC8)', flex: 0.4, rowGap: 16, borderRadius: 16, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}>
                                <div style={{ alignItems: 'center', rowGap: 5, justifyContent: 'center', flexDirection: 'column', display: 'flex', padding: 16 }}>
                                    <p style={{ fontSize: 16, fontWeight: 'normal', color: AppColors.white, margin: 0 }}>{AppStrings.basic}</p>
                                    <p style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.white, margin: 0 }}>$10 / Mon</p>

                                </div>

                                <Button onClick={() => { }} style={{ borderRadius: 100, backgroundColor: '#96AAA7', alignItems: 'center', justifyContent: 'center', padding: 8, margin: 16 }}>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.white, margin: 0 }}>{AppStrings.choose}</p>
                                </Button>



                            </div>

                            <div style={{ padding: 16, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', flex: 1, rowGap: 16 }}>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Patients</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Appointments</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Doctors</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>24x7 Support</p>


                            </div>
                        </div>

                        <div style={{ flex: 1, flexDirection: 'column', borderRadius: 16, backgroundColor: AppColors.white, margin: 16, display: 'flex', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', }}>
                            <div

                                style={{ flexDirection: 'column', display: 'flex', background: 'linear-gradient(to right, #BC93C050, #BC93C0)', flex: 0.4, rowGap: 16, borderRadius: 16, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}>
                                <div style={{ alignItems: 'center', rowGap: 5, justifyContent: 'center', flexDirection: 'column', display: 'flex', padding: 16 }}>
                                    <p style={{ fontSize: 16, fontWeight: 'normal', color: AppColors.white, margin: 0 }}>{AppStrings.basic}</p>
                                    <p style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.white, margin: 0 }}>$10 / Mon</p>

                                </div>

                                <Button onClick={() => { }} style={{ borderRadius: 100, backgroundColor: '#906195', alignItems: 'center', justifyContent: 'center', padding: 8, margin: 16 }}>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.white, margin: 0 }}>{AppStrings.choose}</p>
                                </Button>



                            </div>

                            <div style={{ padding: 16, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', flex: 1, rowGap: 16 }}>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Patients</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Appointments</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Doctors</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>24x7 Support</p>


                            </div>
                        </div>
                        <div style={{ flex: 1, flexDirection: 'column', borderRadius: 16, backgroundColor: AppColors.white, margin: 16, display: 'flex', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', }}>
                            <div

                                style={{ flexDirection: 'column', display: 'flex', background: 'linear-gradient(to right, #2C99E950, #2C99E9)', flex: 0.4, rowGap: 16, borderRadius: 16, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, }}>
                                <div style={{ alignItems: 'center', rowGap: 5, justifyContent: 'center', flexDirection: 'column', display: 'flex', padding: 16 }}>
                                    <p style={{ fontSize: 16, fontWeight: 'normal', color: AppColors.white, margin: 0 }}>{AppStrings.basic}</p>
                                    <p style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.white, margin: 0 }}>$10 / Mon</p>

                                </div>

                                <Button onClick={() => { }} style={{ borderRadius: 100, backgroundColor: '#0063AC', alignItems: 'center', justifyContent: 'center', padding: 8, margin: 16 }}>
                                    <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.white, margin: 0 }}>{AppStrings.choose}</p>
                                </Button>



                            </div>

                            <div style={{ padding: 16, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', flex: 1, rowGap: 16 }}>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Patients</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Appointments</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>Unlimited Doctors</p>
                                <p style={{ fontSize: 20, fontWeight: '600', color: AppColors.black, margin: 0 }}>24x7 Support</p>


                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    );
}


export default Membership;