export const AppImages = {
    bg_img:require('../assets/icons/bg_img.png'),
    appointments:require('../assets/icons/appointments.png'),
    blackoutlinecirclegraphic:require('../assets/icons/blackoutlinecirclegraphic.png'),
    chat:require('../assets/icons/chat.png'),
    circlegraphic:require('../assets/icons/circlegraphic.png'),
    greenarrow:require('../assets/icons/greenarrow.png'),
    home:require('../assets/icons/home.png'),
    onboard1:require('../assets/icons/onboard1.png'),
    onboard2:require('../assets/icons/onboard2.png'),
    onboard3:require('../assets/icons/onboard3.png'),
    patients:require('../assets/icons/patients.png'),
    staff:require('../assets/icons/staff.png'),
    whitearrow:require('../assets/icons/whitearrow.png'),
    outlinegraphic:require('../assets/icons/outlinegraphic.png'),
    arrowleft:require('../assets/icons/arrowleft.png'),
    logo:require('../assets/icons/logo.png'),
    cliniclogoweb:require('../assets/icons/cliniclogoweb.png'),


    homeblack:require('../assets/icons/homeblack.png'),

    homefocused:require('../assets/icons/homefocused.png'),
    appointmentsfocused:require('../assets/icons/appointmentsfocused.png'),
    chatfocused:require('../assets/icons/chatfocused.png'),
    patientsfocused:require('../assets/icons/patientsfocused.png'),
    stafffocused:require('../assets/icons/stafffocused.png'),


    menu:require('../assets/icons/menu.png'),
    cross:require('../assets/icons/cross.png'),
    search:require('../assets/icons/search.png'),
    samplelogo:require('../assets/icons/samplelogo.png'),

    samplepatient:require('../assets/icons/samplepatient.png'),

    sampleuser:require('../assets/icons/sampleuser.png'),
    sampleuser2:require('../assets/icons/sampleuser2.png'),
    sampleuser3:require('../assets/icons/sampleuser3.png'),

    attach:require('../assets/icons/attach.png'),
    send:require('../assets/icons/send.png'),

    male:require('../assets/icons/male.png'),
    female:require('../assets/icons/female.png'),

    sampledoctor:require('../assets/icons/sampledoctor.png'),
    department:require('../assets/icons/department.png'),

    plus:require('../assets/icons/plus.png'),
    tick:require('../assets/icons/tick.png'),
    share:require('../assets/icons/share.png'),
    msgicon:require('../assets/icons/msgicon.png'),
    copy:require('../assets/icons/copy.png'),

    name:require('../assets/icons/name.png'),
    email:require('../assets/icons/email.png'),
    phone:require('../assets/icons/phone.png'),
    gender:require('../assets/icons/gender.png'),
    bloodGroup:require('../assets/icons/bloodgroup.png'),
    address:require('../assets/icons/address.png'),
    camera:require('../assets/icons/camera.png'),
    lock:require('../assets/icons/lock.png'),

    success:require('../assets/icons/success.png'),
    error:require('../assets/icons/error.png'),
    question:require('../assets/icons/question.png'),
    greyarrow:require('../assets/icons/greyarrow.png'),


    sampleReport:require('../assets/icons/sampleReport.png'),
    logout:require('../assets/icons/logout.png'),
    calendar:require('../assets/icons/calendar.png'),
    clock:require('../assets/icons/clock.png'),

    symptoms:require('../assets/icons/symptoms.png'),
    membership:require('../assets/icons/membership.png'),
    staffroles:require('../assets/icons/staffroles.png'),
    reporttypes:require('../assets/icons/reporttypes.png'),
    clinicinfo:require('../assets/icons/clinicinfo.png'),
    departments:require('../assets/icons/departments.png'),
    settings:require('../assets/icons/settings.png'),

    drawergraphic:require('../assets/icons/drawergraphic.png'),

    whitetick:require('../assets/icons/whitetick.png'),
    doctorsfocused:require('../assets/icons/doctorsfocused.png'),
    doctors:require('../assets/icons/doctors.png'),
    bell:require('../assets/icons/bell.png'),
    chatwhite:require('../assets/icons/chatwhite.png'),












  


}