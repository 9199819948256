export const AppColors = {
    mainGreen: '#679E96',
    green10: '#679E9610',
    green30: '#679E9630',
    white70: '#FFFFFF70',
    white50: '#FFFFFF50',

    black: '#2A2A2A',
    black20: '#00000020',
    black05: '#00000005',
    black10: '#00000010',

    lightWhite: '#F9FAFB50',
    screenbg: '#F9FAFB',
    lightGreen: '#81C6BB',
    mediumGreen: '#37474F',
    mediumGreen80: '#37474F80',
    greencircle: '#78B6AC',
    topbargreen: '#F6F6F6',
    greyborder: '#9CA3AF',

    grey600: '#4B5563',
    grey500: '#6B7280',
    greydot: '#E6E6E6',
    pureblack: '#000000',
    black50: '#00000050',

    white: '#FFFFFF',
    bggrey: '#F0F2F4',
    red50: '#FF000050',
    red: '#FF0000',
    red10: '#FF000010',
    red20: '#FF000020',
    lightred: '#FDEBE9',
    blue: '#0760F0',
    textgrey: '#72777A',
    messageGrey: '#F2F4F5',
    addgrey: '#E7E7E7',


    //web
    greystroke:'#E5E7EB',
    greybg:'#F1F1F1',

    orange:'#FF8B03',



}