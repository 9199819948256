import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppImages } from "../../../utils/AppImages.tsx";
import { AppColors } from "../../../utils/AppColors.tsx";
import { Button } from "@mui/material";

const Navbar = () => {
    const [data, setData] = useState([
        { id: 1, name: 'Home', img: AppImages.home, focused: true, path: '/' },
        { id: 2, name: 'Chat', img: AppImages.chat, focused: false, path: 'chat' },
        { id: 3, name: 'Patients', img: AppImages.patients, focused: false, path: '/patients' },
        { id: 4, name: 'Appointments', img: AppImages.appointments, focused: false, path: '/appointments' },
        { id: 12, name: 'Profile', img: AppImages.settings, focused: false, path: '/profile' },
        { id: 5, name: 'Departments', img: AppImages.departments, focused: false, path: '/departments' },
        { id: 6, name: 'Symptoms', img: AppImages.symptoms, focused: false, path: '/symptoms' },
        { id: 7, name: 'Staff', img: AppImages.staff, focused: false, path: '/staff' },
        { id: 8, name: 'Membership', img: AppImages.membership, focused: false, path: '/membership' },
        { id: 9, name: 'Staff Roles', img: AppImages.staffroles, focused: false, path: '/staffroles' },
        { id: 10, name: 'Report Types', img: AppImages.reporttypes, focused: false, path: '/reporttypes' },
        { id: 11, name: 'Clinic Info', img: AppImages.clinicinfo, focused: false, path: '/clinicinfo' },

    ]);

    // Function to update the focused property
    const updateFocusedItem = (id) => {
        const updatedData = data.map(item =>
            item.id === id ? { ...item, focused: true } : { ...item, focused: false }
        );
        setData(updatedData);
    };
    return (
        <div style={{ backgroundColor: AppColors.white, flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column',padding:16 }}>
                <Button style={{flexDirection:'column',display: 'flex', alignItems: 'center',}} component={Link} to={'/'} onClick={()=>{}}>
                    <img src={AppImages.cliniclogoweb} style={{ height: 100, width: 100, objectFit: 'contain', }} alt="Logo" />
                <div style={{ flexDirection: 'column', alignItems: 'center', rowGap: 5, marginTop: 16 }}>
                    <h1 style={{ fontSize: 24, color: AppColors.black, margin: 0, textAlign: 'center' }}>{'PIMS'}</h1>
                    <p style={{ fontSize: 16, color: AppColors.grey600, margin: 0, textAlign: 'center' }}>{'Your Tagline'}</p>
                </div>
                </Button>

                <div style={{ width: '100%', height: 1, backgroundColor: AppColors.black20, marginTop: 16, marginBottom: 16 }}></div>

                <div style={{ width: '100%', overflowY: 'scroll',flex:1,scrollbarWidth:'none' }}>
                    {data.map(item => (
                        <Button onClick={() => { updateFocusedItem(item.id) }} style={{ width: '100%', marginTop: 8, alignItems: 'center', justifyContent: 'left' }} component={Link} to={item.path} >
                            <div style={{opacity:item.focused?1:0.3, width: '100%', flexDirection: 'row', columnGap: 8, display: 'flex', alignItems: 'center', padding: 8, backgroundColor: item.focused ? AppColors.green30 : AppColors.white, borderRadius: 16 }} key={item.id}>
                                <img src={item.img} style={{ height: 24, width: 24, objectFit: 'contain', }} alt={item.name} />
                                <p style={{fontWeight:item.focused?'bold':'normal', fontSize: 16, color: item.focused ? AppColors.mainGreen : AppColors.black, margin: 0, textAlign: 'center' }}>{item.name}</p>
                            </div>
                        </Button>
                    ))}
                </div>
        </div >
    );
};

export default Navbar;