import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import MessageComp from './MessageComp.tsx';

function ChatComp() {

    const [dataTypes, setDataTypes] = useState([

        {
            userimg: AppImages.sampleuser,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser3,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: '23rd Apr',
        },

        {
            userimg: AppImages.sampleuser,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
        {
            userimg: AppImages.sampleuser2,
            username: 'Joseph Kezik',
            lastmessage: 'Sorry i’ii be lateabout 15 minutes',
            time: 'Today',
            messages: '2'
        },
      
       




    ])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginRight: 16, marginLeft: 16, backgroundColor: AppColors.white, borderRadius: 8, padding: 16, flex: 1 }}>

            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', }}>
                <div style={{ flex: 1 }}>
                    <h1 style={{ fontSize: 24, fontWeight: 'bold', color: AppColors.black, flex: 1, margin: 0 }}>{'Chat'}</h1>
                    <p style={{ fontSize: 16, fontWeight: '600', color: AppColors.black50, flex: 1, margin: 0 }}>{'Manage your chats from here'}</p>
                </div>
                <div style={{ borderRadius: 1000, borderStyle: 'solid', borderWidth: 2, borderColor: AppColors.mainGreen, paddingRight: 32, paddingLeft: 32, paddingTop: 4, paddingBottom: 4, alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ fontSize: 16, fontWeight: '700', color: AppColors.mainGreen, margin: 0 }}>{'See All'}</p>
                </div>



            </div>

            <div style={{ flexDirection: 'column', rowGap: 16, display: 'flex', flex: 1, marginTop: 16 }}>
                {dataTypes.map(item => (
                    <MessageComp item={item} />
                ))}
            </div>

        </div>
    );
}

export default ChatComp;