import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import TopBar from '../../../common/components/TopBar.tsx';

import ChatComp from '../../chat/components/ChatComp.tsx';
import DataTypes from '../../home/components/DataTypes.tsx';
import AppointmentComp from '../../home/components/AppointmentComp.tsx';
import AppointmentHandler from '../../appointments/components/AppointmentHandler.tsx';
import AppointmentComp2 from '../../appointments/components/AppointmentComp2.tsx';
import PatientComp from '../components/PatientsComp.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


function Patients() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }


    const [sampleData, setSampleData] = useState([

        {
            patientName: 'Hania Amir',
            patientId: 'PADSD-784',

            gender: 'male',
        },
        {
            patientName: 'Hania Amir',
            patientId: 'PADSD-987',

            gender: 'female',

        },
        {
            patientName: 'Hania Amir',
            patientId: 'PADSD-987',

            gender: 'female',

        },
        {
            patientName: 'Hania Amir',
            patientId: 'PADSD-784',

            gender: 'male',
        },
        {
            patientName: 'Hania Amir',
            patientId: 'PADSD-987',

            gender: 'female',

        },
        {
            patientName: 'Hania Amir',
            patientId: 'PADSD-987',

            gender: 'female',

        }, {
            patientName: 'Hania Amir',
            patientId: 'PADSD-784',

            gender: 'male',
        },


    ])



    const [activeTab, setactiveTab] = useState('0')
    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Patients'} userData={item} />
            <div style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', flexDirection: 'row', rowGap: 16, display: 'grid', marginTop: 16, columnGap: 16, flexWrap: 'wrap', marginRight: 16, marginLeft: 16, }}>

                {sampleData.map(item => (
                    <PatientComp item={item} />
                ))}
            </div>

            <Button component={Link} to={'/addPatient'} style={{ width: 100, height: 100, backgroundColor: AppColors.mainGreen, borderRadius: 1000, position: 'absolute', bottom: 64, right: 64, alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: 32, height: 32 }} src={AppImages.plus} />
            </Button>
        </div>
    );
}

export default Patients;