import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { AppImages } from '../../../utils/AppImages.tsx';
import StaffComp from '../staff/components/StaffComp.tsx';
import TopBar from '../../common/components/TopBar.tsx';
import AdminItem from '../components/AdminItem.tsx';
import { AppColors } from '../../../utils/AppColors.tsx';
import { Button } from '@mui/material';



function Departments() {


    const item = {
        name: 'Hania Amir',
        img: AppImages.samplepatient
    }

    const [texts, setText] = useState('')

    const [items, setItem] = useState({
        selectall: false,
        departments: [
            {
                title: 'Family Practice',
                selected: false,
            },
            {
                title: 'Family Practice',
                selected: false,
            },
            {
                title: 'Family Practice',
                selected: false,
            },
            {
                title: 'Family Practice ',
                selected: false,
            },
        ]

    })
    const [selected, setSelected] = useState(false)
    const [add, setAdd] = useState(false)

    const changeItem = (index) => {
        const updatedDepartments = [...items.departments];
        updatedDepartments[index].selected = !updatedDepartments[index].selected;
        setItem({
            ...items,
            departments: updatedDepartments
        });

        const anySelected = updatedDepartments.some(item => item.selected);
        setSelected(anySelected)
    };

    const selectAllItems = () => {
        const updatedDepartments = items.departments.map(item => ({
            ...item,
            selected: true
        }));

        setItem({
            ...items,
            departments: updatedDepartments
        });
    };

    const deleteSelectedItems = () => {
        const updatedDepartments = items.departments.filter(item => !item.selected);

        setItem({
            ...items,
            departments: updatedDepartments
        });

        setSelected(false)
    };

    const addItem = () => {

        const newItem = {
            title: texts,
            selected: false
        }
        const updatedDepartments = [...items.departments, newItem];
        setItem({
            ...items,
            departments: updatedDepartments
        });

        setText('')

        setAdd(false)
    };
    const handleText = (event) => {
        setText(event.target.value);
    };

    return (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', rowGap: 16, overflow: 'scroll' }}>
            <TopBar page={'Departments'} userData={item} />

            {add && (
                <div style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 16, marginRight: 16, marginTop: 0, display: 'flex' }}>
                    <div style={{ borderRadius: 8, flex: 1, flexDirection: 'row', backgroundColor: AppColors.black10, padding: 8, alignItems: 'center', columnGap: 16, display: 'flex' }}>
                        <input style={{
                            fontSize: 14,
                            borderRadius: 8,
                            flex: 1,
                            padding: 16,
                            backgroundColor: AppColors.screenbg,
                            border: `1px solid ${AppColors.black10}`, // Specify the border color
                            outline: 'none', // Optionally remove the outline
                        }} onChange={handleText} placeholder={'Add Department'} />
                        <Button onClick={() => setAdd(false)}>
                            <div style={{ width: 40, height: 40, borderRadius: 1000, backgroundColor: AppColors.screenbg, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                <img style={{ width: 16, height: 16 }} src={AppImages.cross} />

                            </div>
                        </Button>
                    </div>

                </div>)}

            {selected && items.departments.length > 0 && (
                <div style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: 16, marginRight: 16, display: 'flex' }}>
                    <Button onClick={() => { selectAllItems() }} style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1000, borderColor: AppColors.mainGreen, paddingLeft: 32, paddingRight: 32, paddingBottom: 8, paddingTop: 8 }}>
                        <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.mainGreen, margin: 0 }}>Select All</p>
                    </Button>
                    <Button onClick={() => { deleteSelectedItems() }} style={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1000, borderColor: AppColors.red, paddingLeft: 32, paddingRight: 32, paddingBottom: 8, paddingTop: 8 }}>
                        <p style={{ fontSize: 16, fontWeight: 'bold', color: AppColors.red, margin: 0 }}>Delete</p>

                    </Button>

                </div>
            )}
            <div style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', flexDirection: 'row', rowGap: 16, display: 'grid', marginTop: 16, columnGap: 16, flexWrap: 'wrap', marginRight: 16, marginLeft: 16, }}>

                {items.departments.map((item, index) => (
                    <AdminItem changeItem={changeItem} index={index} item={item} />
                ))}
            </div>
            {add == false && (
                <Button onClick={() => setAdd(true)} style={{ width: 64, height: 64, backgroundColor: AppColors.mainGreen, borderRadius: 1000, position: 'absolute', bottom: 64, right: 64, alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ width: 32, height: 32 }} src={AppImages.plus} />

                </Button>
            )}

            {add && (
                <Button onClick={() => { addItem() }} style={{ margin: 16, borderWidth: 1, borderRadius: 1000, borderColor: AppColors.mainGreen, borderStyle: 'solid', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ fontSize: 16, fontWeight: 'bold', margin: 4, color: AppColors.mainGreen }}>Add</p>
                </Button>
            )}

        </div>
    );
}

export default Departments;