import DatePicker from "react-native-date-picker"
import { AppImages } from "../../../utils/AppImages.tsx"
import { AppColors } from "../../../utils/AppColors.tsx"
import { Button } from "@mui/material"




const AdminItem = ({ item, index, changeItem }) => {




    return (

        <div style={{ flexDirection: 'row', backgroundColor: item.selected ? AppColors.mainGreen : AppColors.black10, padding: 16, borderRadius: 8, alignItems: 'center', display: 'flex' }}>
            <p style={{ color: item.selected ? AppColors.white : AppColors.black, flex: 1, margin: 0 }}>{item.title}</p>

            {item.selected ?
                <Button onClick={() => { changeItem(index) }} >
                    <div style={{alignItems: 'center', justifyContent: 'center', borderRadius: 1000, borderWidth: 2, width: 16, height: 16, borderColor: AppColors.white,display:'flex',borderStyle:'solid' }}>
                    <img src={AppImages.whitetick} style={{ width: 14, height: 14 }} />

                    </div>
                </Button>
                :
                <Button onClick={() => { changeItem(index) }} style={{ }}>
                    <div style={{ alignItems: 'center', justifyContent: 'center', borderRadius: 1000, borderWidth: 2, width: 16, height: 16, borderColor: AppColors.black50,display:'flex',borderStyle:'solid'}}></div>
                </Button>
            }


        </div>

    )
}



export default AdminItem