import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import { Button } from '@mui/material';

function AppointmentHandler({ activeTab, setactiveTab }) {



    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginRight: 100, marginLeft: 100, backgroundColor: AppColors.topbargreen, borderRadius: 8 }}>

            <Button onClick={()=>{setactiveTab('0')}} style={activeTab == '0' ? innerstyles.activeTab : innerstyles.tab}>
                <h1 style={activeTab == '0' ? innerstyles.activeText : innerstyles.text}>{'Scheduled'}</h1>
                {activeTab == '0' && (
                    <div style={{ borderRadius: 1000, backgroundColor: AppColors.orange, width: 24, height: 24, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                        <p style={{ fontSize: 12, fontWeight: '700', color: AppColors.white, margin: 0 }}>{2}</p>
                    </div>
                )}

            </Button>
            <Button  onClick={()=>{setactiveTab('1')}}  style={activeTab == '1' ? innerstyles.activeTab : innerstyles.tab}>
                <h1 style={activeTab == '1' ? innerstyles.activeText : innerstyles.text}>{'Completed'}</h1>
                {activeTab == '1' && (
                    <div style={{ borderRadius: 1000, backgroundColor: AppColors.orange, width: 24, height: 24, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <p style={{ fontSize: 12, fontWeight: '700', color: AppColors.white, margin: 0 }}>{2}</p>
                    </div>
                )}
            </Button>
            <Button  onClick={()=>{setactiveTab('2')}}  style={activeTab == '2' ? innerstyles.activeTab : innerstyles.tab}>
                <h1 style={activeTab == '2' ? innerstyles.activeText : innerstyles.text}>{'Cancelled '}</h1>
                {activeTab == '2' && (
                    <div style={{ borderRadius: 1000, backgroundColor: AppColors.orange, width: 24, height: 24, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <p style={{ fontSize: 12, fontWeight: '700', color: AppColors.white, margin: 0 }}>{2}</p>
                    </div>
                )}
            </Button>
            <Button  onClick={()=>{setactiveTab('3')}}  style={activeTab == '3' ? innerstyles.activeTab : innerstyles.tab}>
                <h1 style={activeTab == '3' ? innerstyles.activeText : innerstyles.text}>{'Late'}</h1>
                {activeTab == '3' && (
                    <div style={{ borderRadius: 1000, backgroundColor: AppColors.orange, width: 24, height: 24, alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <p style={{ fontSize: 12, fontWeight: '700', color: AppColors.white, margin: 0 }}>{2}</p>
                    </div>
                )}
            </Button>
        </div>
    );
}


const innerstyles = {
    tab: {
        flex: 1, display: 'flex', borderRadius: 8, padding: 16, flexDirection: 'row', alignItems: 'center', columnGap: 16,justifyContent:'center'
    },
    activeTab: {
        flex: 1, backgroundColor: AppColors.mainGreen, display: 'flex', borderRadius: 8, padding: 16, flexDirection: 'row', alignItems: 'center', columnGap: 16,justifyContent:'center'
    },
    text: {
        fontSize: 16, fontWeight: 'bold', color: AppColors.mainGreen, margin: 0
    },
    activeText: {
        fontSize: 16, fontWeight: 'bold', color: AppColors.white, margin: 0    }
}
export default AppointmentHandler;