import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isLoggedin: false,
    isAdmin: true,
};



export const AppSlice = createSlice({
    name: "AppReducer",
    initialState,
    reducers: {


        setisLoggedin: (state, action) => {
            console.log(action.payload);
            
            state.isLoggedin = action.payload
        },
        setisAdmin: (state, action) => {
            console.log(action.payload);

            state.isAdmin = action.payload
        },




    },
});

export const {

    setisLoggedin,
    setisAdmin,



} = AppSlice.actions;

export default AppSlice.reducer;