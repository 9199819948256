import React, { useState } from 'react';

// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import { AppColors } from '../../../../utils/AppColors.tsx';
import { AppImages } from '../../../../utils/AppImages.tsx';
import { AppStrings } from '../../../../utils/AppStrings.tsx';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function PatientComp({ item }) {



    return (
        <Button component={Link} to={'/patientDetail'} style={{ display: 'flex', backgroundColor: AppColors.white, borderRadius: 8, padding: 16, alignItems: 'center', justifyContent: 'center', flex: 1, rowGap: 8, flexDirection: 'column' }}>
            <img src={AppImages.samplepatient} style={{ width: 64, height: 64, borderRadius: 1000 }} />


            <p style={{ color: AppColors.pureblack, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{item.patientName}</p>
            <p style={{ color: AppColors.black50, margin: 0, fontSize: 16, fontWeight: 'normal' }}>{item.patientId}</p>

            <Button component={Link} to={'/addAppointment'} style={{ flex: 1, borderRadius: 8, backgroundColor: AppColors.green10, alignItems: 'center', justifyContent: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                <p style={{ color: AppColors.mainGreen, margin: 0, fontSize: 16, fontWeight: 'bold' }}>{AppStrings.appointment}</p>
            </Button>
            <img src={item.gender == 'male' ? AppImages.male : AppImages.female} style={{ width: 16, height: 16, position: 'absolute', top: 16, right: 16 }} />



        </Button>
    );
}

export default PatientComp;